/* Based on arcticicestudio's Nord theme */
/* https://github.com/arcticicestudio/nord */

.cm-s-nord.CodeMirror { background: #2e3440; color: #d8dee9; }
.cm-s-nord div.CodeMirror-selected { background: #434c5e; }
.cm-s-nord .CodeMirror-line::selection, .cm-s-nord .CodeMirror-line > span::selection, .cm-s-nord .CodeMirror-line > span > span::selection { background: #3b4252; }
.cm-s-nord .CodeMirror-line::-moz-selection, .cm-s-nord .CodeMirror-line > span::-moz-selection, .cm-s-nord .CodeMirror-line > span > span::-moz-selection { background: #3b4252; }
.cm-s-nord .CodeMirror-gutters { background: #2e3440; border-right: 0px; }
.cm-s-nord .CodeMirror-guttermarker { color: #4c566a; }
.cm-s-nord .CodeMirror-guttermarker-subtle { color: #4c566a; }
.cm-s-nord .CodeMirror-linenumber { color: #4c566a; }
.cm-s-nord .CodeMirror-cursor { border-left: 1px solid #f8f8f0; }

.cm-s-nord span.cm-comment { color: #4c566a; }
.cm-s-nord span.cm-atom { color: #b48ead; }
.cm-s-nord span.cm-number { color: #b48ead; }

.cm-s-nord span.cm-comment.cm-attribute { color: #97b757; }
.cm-s-nord span.cm-comment.cm-def { color: #bc9262; }
.cm-s-nord span.cm-comment.cm-tag { color: #bc6283; }
.cm-s-nord span.cm-comment.cm-type { color: #5998a6; }

.cm-s-nord span.cm-property, .cm-s-nord span.cm-attribute { color: #8FBCBB; }
.cm-s-nord span.cm-keyword { color: #81A1C1; }
.cm-s-nord span.cm-builtin { color: #81A1C1; }
.cm-s-nord span.cm-string { color: #A3BE8C; }

.cm-s-nord span.cm-variable { color: #d8dee9; }
.cm-s-nord span.cm-variable-2 { color: #d8dee9; }
.cm-s-nord span.cm-variable-3, .cm-s-nord span.cm-type { color: #d8dee9; }
.cm-s-nord span.cm-def { color: #8FBCBB; }
.cm-s-nord span.cm-bracket { color: #81A1C1; }
.cm-s-nord span.cm-tag { color: #bf616a; }
.cm-s-nord span.cm-header { color: #b48ead; }
.cm-s-nord span.cm-link { color: #b48ead; }
.cm-s-nord span.cm-error { background: #bf616a; color: #f8f8f0; }

.cm-s-nord .CodeMirror-activeline-background { background: #3b4252; }
.cm-s-nord .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
